import React from 'react';

import { Form } from 'antd';

import InputResend from '@components/Element/Input/InputResend';

export const InputField = ({ name, placeholder, onSend, setCountDown, countdown, theme }) => {
  return (
    <Form.Item
      name={name}
    >
      <InputResend
        placeholder={placeholder}
        onSend={onSend}
        theme={theme}
        setCountDown={setCountDown}
        countdown={countdown}
      />
    </Form.Item>
  )
}
