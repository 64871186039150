import React, { useState } from 'react';
import reactDom from 'react-dom'

import { Form, message } from 'antd';

import { CustomerAPI } from '@api/index'
import { getDeviceId } from '@utils/getDeviceId'
import I18n from '@utils/i18n';

import { ModalWrapper } from './ModalWrapper';
import { VerifyMain } from './VerifyForm';

import styles from './index.module.css';

const LoginVerifyModal = (props) => {
  const {
    isBindEmail, // 是否绑定邮箱
    isBindPhone, // 是否绑定手机
    isBindGoogle, // 是否绑定谷歌账号
    resolve,
    reject,
    safetyStore,
    verifyType = '17'
  } = props;
  const [visible, setVisible] = useState(true);
  const [isCountDown, setCountDown] = useState(false);
  const [isCountDownemail, setCountDownemail] = useState(false);
  const [form] = Form.useForm();
  const onClose = () => {
    setVisible(false);
    reject();
  }
  const onFinish = async (value) => {
    const deviceId = await getDeviceId()
    try {
      const res = await safetyStore.getfull({
        ...value,
        type: verifyType,
        deviceId
      })
      resolve(res);
      onClose()
    } catch (error) {
      reject(error)
    }
  }

  const onResend = async (eletype, params) => {
    // params.businessType = '17';
    // params.type = '17';
    params.mobile = '';
    params.deviceId = await getDeviceId()
    const res = await CustomerAPI[eletype === 'email' ? 'sendEmailCode' : 'sendPhoneCode'](params);
    if (!res.success) {
      message.error(I18n.t(res.msgKey))
      return
    }
    message.success(I18n.t(res.msgKey))
    eletype === 'email' ? setCountDownemail(true) : setCountDown(true)
  }
  return (
    <ModalWrapper closeModal={onClose} visible={visible}>
      <Form form={form} onFinish={onFinish}>
        <VerifyMain
          isBindEmail={isBindEmail}
          isBindPhone={isBindPhone}
          isBindGoogle={isBindGoogle}
          setCountDownemail={setCountDownemail}
          isCountDownemail={isCountDownemail}
          onResend={onResend}
          businessType={verifyType}
          type={verifyType}
          setCountDown={setCountDown}
          isCountDown={isCountDown}
          className={styles.loginForm}
          okText={I18n.t('forgetPass_step_three_confim')}
          email="email"
        />
      </Form>
    </ModalWrapper>
  )
}

export const openLoginVerifyModal = (props) => {
  const el = document.createElement('div');
  return new Promise((resolve, reject) => {
    reactDom.render(<LoginVerifyModal {...props} resolve={resolve} reject={reject} />, el)
  })
}
