import { useBindAuth as useWalletBindAuth, useOauthNonce } from 'api-hooks'
import { useEOAWalletConnectKit } from 'particle-connect';

export const useBindAuth = ({ getAddress, getChainName, getSignture }) => {
  const mutationOauthNonce = useOauthNonce(null, null);
  const mutationBindAuth = useWalletBindAuth(null);
  const { getWalletSignture, getWalletChainName } = useEOAWalletConnectKit();

  const bind = async ({ thirdAuthSource, publicAddress = '', loginType = 'eoa', provider = null }) => {
    // 1.get nonce
    const address = publicAddress || (await getAddress())
    const publicKey = address

    let nonce
    try {
      const params: any = { publicKey }
      const data = await mutationOauthNonce.mutateAsync(params)
      nonce = data.data.nonce
      // console.error('nonce', nonce)
    } catch (error) {
      console.error(error)
    } finally {
      console.log('done')
    }

    // 2.wallet bind

    try {
      const walletType = loginType === 'eoa' ? await getWalletChainName(provider) : await getChainName()

      let signature = ''
      if (loginType === 'eoa') {
        signature = await getWalletSignture(provider, {
          address, nonce, publicKey, walletType
        }, address)
      } else {
        signature = await getSignture({
          address, nonce, publicKey, walletType
        }, address)
      }

      const bindOauthQueryParams: any = {
        publicKey,
        walletType,
        nonce,
        signature,
        thirdAuthSource,
        address,
        platform: 'particle'
      }
      const { data }: any = await mutationBindAuth.mutateAsync(bindOauthQueryParams)

      if (data.success) {
        return {
          data: data.obj,
          error: null
        }
      } else {
        return {
          data: null,
          error: data.msgKey
        }
      }
    } catch (error) {
      console.error(error)
      return {
        userInfo: null,
        error: error.message
      }
    } finally {
      console.log('done')
    }
  }
  return {
    bind
  }
}
