// eslint-disable-file no-use-before-define

import React from 'react';

import Image from 'next/legacy/image';

import { openModal } from '@components/Element'
import { I18n } from '@utils/index';

import NavigationBar from './NavigationBar';
const ModalContent = (props) => {
  const { close, callBack, confirmText } = props
  const onConfim = () => {
    close()
    callBack?.()
  }

  const skipBind = () => {
    close()
    callBack?.(true)
  }

  return (
    <div className='flex flex-col items-center p-8 pt-0'>
      <div className='pb-12 max-[600px]:w-full justify-center items-center'>
        <NavigationBar
          skipBind={skipBind}
          step={0}
        />
        <div className='flex flex-col justify-between items-center w-[576px] max-[600px]:w-full'>
          <div className="inline-flex flex-col items-center w-fit h-fit mt-4">
            <span className="text-left align-top text-2xl font-DINPro font-bold whitespace-nowrap text-[#1f2126] inline-flex">
              {I18n.t('32VbB3Uz')}
            </span>
            <span className="text-left align-top text-base font-DINPro font-normal whitespace-nowrap text-[#696f7f] mt-2 inline-flex">
              {I18n.t('XG4pdpYL')}
            </span>
          </div>
          <div className='w-[260px] h-[216px] mt-[42px]'>
            <Image
              src={require('@images/web3/bind_logo.webp')}
              alt="kikitrade"
              className="w-[260px] h-[216px] cursor-pointer"
              onClick={close}
            />
          </div>
        </div>
      </div>
      <div onClick={onConfim} className='flex flex-row justify-between items-center mt-24'>
        <button className="btn-primary w-[280px]" >
          {confirmText}
        </button>
      </div>
    </div>
  );
}

export const OpenAnnouncementModal = (props) => {
  return openModal({
    title: '',
    footer: null,
    closable: false,
    centered: true,
    children: (close) => <ModalContent {...props} close={close}/>
  })
}
