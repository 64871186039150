const EMAIL = 'email';
const MOBILE = 'mobile';

export const getMobileOrEmailList = (name) => {
  if (name !== EMAIL && name !== MOBILE) {
    return []
  }
  const list = localStorage.getItem(`${name}List`);
  try {
    return JSON.parse(list) || [];
  } catch (error) {
    return []
  }
}

export const saveMobileOrEmail = (params) => {
  if (params?.[EMAIL]) {
    let list = getMobileOrEmailList(EMAIL);
    const isrepeat = list.find(item => item[EMAIL] === params?.[EMAIL])
    if (isrepeat) {
      return
    }
    if (list.length >= 5) {
      list = list.slice(list.length - 5, list.length - 1)
    }
    list.push({ email: params[EMAIL] })
    localStorage.setItem(`${EMAIL}List`, JSON.stringify(list))
    return
  }
  if (params?.[MOBILE] && params?.country) {
    let list = getMobileOrEmailList(MOBILE);
    const isrepeat = list.find(item => item[MOBILE] === params?.[MOBILE] && item.country === params.country.id)
    if (isrepeat) {
      return
    }
    if (list.length >= 5) {
      list = list.slice(list.length - 5, list.length - 1)
    }
    list.push({ mobile: params[MOBILE], country: params.country.id, countryNum: params.country.telephoneCode })
    localStorage.setItem(`${MOBILE}List`, JSON.stringify(list))
  }
}
