import React, { useCallback, useContext, useState } from 'react';

import { message } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react';

import { OpenAnnouncementModal } from '@/web3/components/wallet/modal/Announcement'
import { OpenConnectTipModal } from '@/web3/components/wallet/modal/ConnectTip'
import { OpenWalletBindModal } from '@/web3/components/wallet/modal/WalletBind'
import { openLoginNoCaptchaModal } from '@components/NoCaptchaModal/loginNoCaptchaModal'
import {
  THEME
} from '@constants/index';
import { TabPane, Tabs } from '@element/Tabs';
import { useIsomorphicLayoutEffect } from '@lib/hooks';
import pubsub from '@lib/scripts/pubsub'
import { store } from '@store/index';
import { QueryClientContext } from '@tanstack/react-query';
import { getRemoteConfigValue } from '@utils/firebase'
import { getDeviceId } from '@utils/getDeviceId';
import helper from '@utils/helper';
import I18n from '@utils/i18n';
import { getExecute } from '@utils/index';
import {
  EmailLogin,
  MobileLogin,
  WalletLogin
} from '@widget/login/rightSide';
import { openLoginVerifyModal } from '@widget/wallet/deposit/AssetWidthDrawAddressModal/LoginVerifyModal';

import { OtherLogin } from '../OtherLogin';

import styles from './index.module.less';
interface HTMLCollectionOf<T extends Element> extends HTMLCollection {
  item(index: number): T;
  namedItem(name: string): T;
  [index: number]: T;
}
const MAX_ERR_COUNT = 5;

// 处理错误消息
const handleErrMsg = (err) => {
  console.log(err, 'errerrerr')
  if (err?.code === 'auth/account-exists-with-different-credential') {
    message.error(I18n.t('JJaSypTS'));
  }
  if (err?.code === 'auth/cancelled-popup-request') {
    message.error(I18n.t('eJ8AumhI'));
  }
  if (!err?.msgKey && err.message) {
    message.error(I18n.t(err.message));
    return;
  }
  if (!err?.msgKey) {
    return;
  }
  if (
    [
      'customer.login.password.error.email',
      'customer.login.password.error.mobile'
    ].includes(err.msgKey)
  ) {
    const errCount = MAX_ERR_COUNT - err.obj?.errorCount;
    if (errCount <= 0) {
      message.error(I18n.t('account.max.err.count'));
      return;
    }
    message.error(I18n.t(err.msgKey, { time: errCount }));
    return;
  }
  message.error(I18n.t(err.msgKey));
};

interface LoginProps {
  showOtherLogin?: boolean;
  close?: () => void;
  extraRequest?: (value?: any) => void;

  /** tabClass */
  tabClass?: string;
  /** 支持三方登录的列表 不传则不支持  */
  oAuthList?: string[];
  /** 底部按钮 方便在H5控制样式 */
  bottomClass?: string;
  /** 登录按钮 class */
  btnClass?: string;
  /** 登录按钮文字 */
  btnText?: string;
  /** 登录成功的回调 */
  cb?: (params: any) => void;
  /** 主题 */
  theme?: Theme;
  /** 三方登录的形式 popup 弹出式 redirect 重定向式 */
  mode?: 'popup' | 'redirect';
  loginType?: string;
  hideTab?: 'wallet' | 'normal';
}

export const Login: React.FC<LoginProps> = observer((props) => {
  const { oAuthList = [], mode, tabClass, bottomClass, btnText, btnClass, cb, theme, loginType, hideTab } = props;
  const [activeKey, setActiveKey] = useState(loginType === 'wallet' ? '3' : '1');

  const queryClient = useContext(QueryClientContext);
  useIsomorphicLayoutEffect(() => {
    if (loginType) {
      // manual trigger walet login
      const elm = (document.getElementsByClassName('ant-tabs-tab')as HTMLCollectionOf<HTMLElement>)[loginType === 'wallet' ? 2 : 0]
      elm?.click()
    }
  }, [loginType])
  // const isPc = useClient();
  // 1. 登录
  const loginFn = (params, options) => {
    let mobile = params?.mobile
    if (!options?.headers?.risk_token) {
      mobile = params?.mobile
        ? encodeURIComponent(`+${params?.country?.telephoneCode}${params.mobile}`)
        : undefined;
    }

    const req = {
      email: params?.email,
      mobile,
      source: '0',
      country: params?.country?.id,
      password: params?.password,
      pTokenType: 'web',
      token: store.userStore.webToken,
      deviceId: params.deviceId,
      tokenId: '',
      traceId: '',
      mode: 'PWD'
    };
    return new Promise<{ success: boolean; obj: Record<string, string> }>(
      (resolve, reject) => {
        store.userStore.doLogin(
          req,
          options,
          (res) => {
            resolve(res);
          },
          (err) => reject(err)
        );
      }
    );
  };

  // 2. 获取用户社交信息
  const getUserSocialInfo = (userInfo) => {
    return new Promise<void>((resolve, reject) => {
      store.userStore.getUserSocialInfo(
        { userId: userInfo.id, token: userInfo.jwtKey },
        () => {
          resolve();
        },
        (err) => {
          reject(err);
        }
      );
    });
  };

  // 账号密码登录
  const handleLogin = useCallback(async (params) => {
    try {
      const captchaTokenData = await getCaptchaTokenData();
      const deviceId = await getDeviceId();
      params.password = helper.md5(params?.password);
      // 1. 登录
      const paramsData = {
        data: { ...params, deviceId },
        opts: captchaTokenData
      }
      toLogin(paramsData);
    } catch (error) {
      handleErrMsg(error);
    }
    // eslint-disable-next-line
  }, []);

  const riskVerifyLogin = async (params) => {
    const captchaTokenData = await getCaptchaTokenData();
    const opt = params.opts;
    opt.token = captchaTokenData?.token ?? '';
    const paramsData = {
      data: params.data,
      opts: opt
    }
    toLogin(paramsData);
  }

  const getCaptchaTokenData = async () => {
    try {
      const remoteConfigValue = await getRemoteConfigValue('common_switch')
      const _value = JSON.parse(remoteConfigValue.asString())
      let captchaTokenData;
      if (_value.noCaptchaV3 === 'true') {
        // 无痕验证
        captchaTokenData = await getExecute();
      } else {
        // 图片验证
        captchaTokenData = await openLoginNoCaptchaModal();
      }
      console.log('captchaTokenData', captchaTokenData)
      return captchaTokenData;
    } catch (error) {
      handleErrMsg(error);
    }
  }

  const authSuccessCallback = async (userInfo: any, resolve?: (res: any) => void) => {
    const res = userInfo
    if (!res.jwtKey) {
      res.jwtKey = userInfo.jwtKey
    }
    // set userInfo jwtToken
    if (userInfo && userInfo.jwtKey) {
      globalThis?.localStorage?.setItem('auth_jwtKey', userInfo.jwtKey)
      globalThis?.localStorage?.setItem('auth_userId', userInfo.id)
    }
    cb?.({ res });
    resolve?.(res)
  }

  const checkWalletBind = (userInfo) => {
    const successCallback = async (web3UserInfo, resolve) => {
      const res = web3UserInfo
      if (!res.jwtKey) {
        res.jwtKey = userInfo.jwtKey
      }
      await loginSuccessFn(res)
      await getUserSocialInfo(res);
      cb?.({ res });
      resolve(res)
    }

    if (hideTab === 'wallet') {
      // 如果是隐藏钱包的登录展示，则只是提取余额的登录验证
      // 此种情况，值记录token用作提取余额，不整体更新token信息
      // 对于钱包登录的token信息，仍然保留
      return new Promise((resolve, reject) => {
        authSuccessCallback(userInfo, resolve)
        return null;
      });
    }

    // set userInfo jwtToken
    if (userInfo && userInfo.jwtKey) {
      globalThis?.localStorage?.setItem('jwtKey', userInfo.jwtKey)
      globalThis?.localStorage?.setItem('userId', userInfo.id)
    }

    return new Promise((resolve, reject) => {
      if (!userInfo.publicKey) {
        OpenAnnouncementModal({
          confirmText: I18n.t('SsPeGz8f'),
          callBack: async (isSkip?: boolean) => {
            // 根据callback中参数决定是否跳过，如果跳过的话，默认用户登陆成功，不再做其他处理
            if (isSkip) {
              await successCallback(userInfo, resolve)
              return null;
            }
            OpenWalletBindModal({
              queryClient,
              userName: userInfo.email || userInfo.phone,
              userInfo: userInfo,
              callBack: async (web3UserInfo) => {
                await successCallback(web3UserInfo, resolve)
              }
            })
          }
        })
      } else {
        OpenConnectTipModal({
          content: I18n.t('6yJAtZIc'),
          userInfo: userInfo,
          callBack: () => {
            // manual trigger walet login
            const elm = (document.getElementsByClassName('ant-tabs-tab')as HTMLCollectionOf<HTMLElement>)[2]
            elm?.click()
          }
        })
      }
    });
  }

  const toLogin = async (params) => {
    try {
      const res = await loginFn(params?.data, params?.opts);
      const useInfo = res?.obj;
      // wallet bind logic

      await checkWalletBind(useInfo)
      // useInfo = newUserInfo
      // if (!res.success) {
      //   // 2. 非常用设备
      //   const { isEmailCertified, isGoogleCertified, isPhoneCertified } =
      //     res.obj;
      //   useInfo = await openLoginVerifyModal({
      //     safetyStore: store.getSafetyStore,
      //     isBindEmail: isEmailCertified,
      //     isBindPhone: isPhoneCertified,
      //     isBindGoogle: isGoogleCertified
      //   });
      // }
      // store.userStore.loginSuccess(useInfo);
      // console.log(handlepwdLevle(params?.password), 'sadasdZ');
      // store.userStore.updateUserInfor({
      //   pwdLevle: handlepwdLevle(params?.password)
      // });
      // await getUserSocialInfo(useInfo)

      // cb?.({ form: params, res: useInfo });
      // return Promise.resolve();
    } catch (error) {
      handleErrMsg(error);
      return Promise.reject(error);
    }
  }
  const loginSuccessFn = (useInfo) => {
    store.userStore.loginSuccess(useInfo);
  }

  useIsomorphicLayoutEffect(() => {
    sessionStorage.setItem(
      'register_home',
      JSON.stringify({ type: activeKey })
    );
    pubsub.subscribe('DEVICE_RISK_VERIFY_PASS_login', riskVerifyLogin)
    return () => {
      pubsub.unSubscribe('DEVICE_RISK_VERIFY_PASS_login', riskVerifyLogin);
    }
  }, [activeKey]);

  if (hideTab === 'wallet') {
    return (
      <div className={cn({ [styles.dark_container]: theme === THEME.DARK })}>
        <Tabs
          onChange={setActiveKey}
          activeKey={activeKey}
          type="login"
          theme={theme}
          className={cn(styles.overflow, tabClass)}
        >
          <TabPane tab={I18n.t('mobile')} key="1" type="login" theme={theme}>
            <MobileLogin
              // activeKey={activeKey}
              clickLogin={handleLogin.bind(this)}
              bottomClass={bottomClass}
              btnText={btnText}
              btnClass={btnClass}
              theme={theme}
            />
          </TabPane>
          <TabPane tab={I18n.t('email')} key="2" type="login">
            <EmailLogin
              // activeKey={activeKey}
              clickLogin={handleLogin.bind(this)}
              bottomClass={bottomClass}
              btnText={btnText}
              btnClass={btnClass}
              theme={theme}
            />
          </TabPane>
        </Tabs>
        <OtherLogin
            openLoginVerifyModal={openLoginVerifyModal}
            loginSuccess={async ({ res }) => {
            // 如果是隐藏钱包的登录展示，则只是提取余额的登录验证
            // 此种情况，只记录token用作提取余额，不整体更新token信息
            // 对于钱包登录的token信息，仍然保留
              await authSuccessCallback(res)
              cb?.({ res });
            }}
            handleErrMsg={handleErrMsg}
            setActiveKey={setActiveKey}
            oAuthList={oAuthList}
            mode={mode}
            theme={theme}
          />
      </div>
    );
  }
  return (
    <div className={cn({ [styles.dark_container]: theme === THEME.DARK })}>
      <WalletLogin
        loginSuccess={async ({ res }) => {
          await loginSuccessFn(res)
          await getUserSocialInfo(res);
          cb?.({ res });
        }}
      />
    </div>
  );
});
