
import React from 'react';

import { message } from 'antd';
import { ParticleModalProvider, useEOAWalletConnectKit } from 'particle-connect'

import { EOAWallet } from '@/web3/components/wallet/eoa'
import ParticleLogo from '@/web3/components/wallet/modal/ParticleLogo';
import { OSWallet } from '@/web3/components/wallet/opensocial'
import { EOAWalletData, OSWalletData } from '@/web3/constants/particle'
import { useLoginAuth } from '@/web3/hooks/login/useLoginAuth'
import { useOSWalletConnect } from '@/web3/hooks/particle/useOSWalletConnect'
import pubsub from '@lib/scripts/pubsub'
import { useParticleConnect } from '@particle-network/connectkit';
import { getPublicAddress } from '@particle-network/connectors';
import { I18n } from '@utils/index';

const Content = ({ loginSuccess }) => {
  const { connectOSWallet, getAddress, getChainName, getSignture } = useOSWalletConnect()
  const { connectEOAWallet } = useEOAWalletConnectKit()

  const { login } = useLoginAuth({ getAddress, getChainName, getSignture })
  const { disconnect } = useParticleConnect()

  const onClickEOAWallet = async (type) => {
    try {
      await disconnect()
      const provider = await connectEOAWallet({ id: type })
      const publicAddress = await getPublicAddress(provider)
      await loginWallet({ publicAddress, loginType: 'eoa', provider })
    } catch (e) {
      console.error(e)
    }
  }

  const onClickOSWallet = async (type) => {
    const ret = await connectOSWallet(type)
    if (ret?.error) {
      return message.error(I18n.t(ret?.error));
    }

    await loginWallet({ loginType: 'opensocial' })
  }

  const loginWallet = async (options) => {
    const { userInfo, error } = await login(options)
    if (userInfo) {
      await loginSuccess({ res: userInfo })
      if (options.loginType === 'eoa') {
        pubsub.publish('CONNECT_WALLET')
      }
    } else {
      message.error(I18n.t(error));
    }
  }

  return (

    <div className='rounded-xl bg-white pb-[6px] flex flex-col h-[392px]  w-fit max-[600px]:w-full'>

      <div className="flex flex-col ">
        <span className="text-left align-top text-sm font-DINPro font-bold whitespace-nowrap text-black inline-flex">
          {I18n.t('vQxkJs55')}
        </span>
        {
          EOAWalletData.map((item) => (
            <EOAWallet
              image={item.image}
              name={item.name}
              id={item.id}
              key={item.id}
              onClick={onClickEOAWallet}
            />
          ))
        }

      </div>
      <div className="flex flex-col items-center w-fit h-fit max-[600px]:w-full mt-[30px]">
        <div className="inline-flex flex-row items-center w-fit h-[15px] mb-[8px]">
          <div
            className="bg-[rgba(0,0,0,0.2)] mr-3 w-[30px] h-[0.5px]"
          />
          <span className="text-left align-top text-[12px] whitespace-nowrap text-black/60">
            {I18n.t('EW20nRel')}
          </span>
          <div
            className="bg-[rgba(0,0,0,0.2)] ml-3 w-[30px] h-[0.5px]"
          />
        </div>
        <ParticleLogo />
        <div className="px-6 max-[600px]:px-0 flex flex-row justify-between mt-2 w-[400px] max-[600px]:w-full h-[66px]">

          {
            OSWalletData.map((item) => (
              <OSWallet
                image={item.image}
                name={item.name}
                id={item.id}
                key={item.id}
                onClick={onClickOSWallet}
              />
            ))
          }

        </div>
      </div>

    </div>
  );
};

export const WalletLogin = (props) => {
  return (
    <ParticleModalProvider>
      <Content {...props}/>
    </ParticleModalProvider>

  )
}
