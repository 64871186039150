import React, { useState } from 'react';

import { Dropdown, Form } from 'antd';
import cn from 'classnames';

import CountrySelect from '@components/CountrySelect'
import { THEME } from '@constants/index'
import { Input } from '@element/index';
import i18n from '@utils/i18n';

import { BaseLogin, MenuList, WrapperItem } from './BaseLogin';

import styles from './index.module.css';
import loginStyles from '@styles/modules/login.module.css';

const FormItem = Form.Item;

const MobileFormItem = ({ name, setVisible, theme }) => {
  return (
    <FormItem
      name={name}
      rules={[
        {
          validator (rule, value) {
            if (!value) {
              return Promise.reject(new Error(i18n.t('can.not.be.empty', { key: i18n.t('mobile') })))
            }
            const reg = /^\d+$/
            if (reg.test(value)) {
              return Promise.resolve()
            }
            return Promise.reject(new Error(i18n.t('wrong.format', { key: i18n.t('mobile') })))
          },
          validateTrigger: 'onBlur'
        }
      ]}
    >
      <Input
        showClear
        tabIndex='1'
        theme={theme}
        className={cn({ [styles.account]: theme !== THEME.DARK })}
        placeholder={i18n.t('register_input_mobile')}
        onBlur={() => {
          setTimeout(() => {
            setVisible?.(false)
          }, 200)
        }}
        onFocus={() => setVisible?.(true)}
        onInput={e => {
          const value = e.target.value;
          if (value) {
            setVisible?.(false)
          } else {
            setVisible?.(true)
          }
        }} />
    </FormItem>
  )
}

const RenderMobile = ({ name, list, form, theme }) => {
  const [visible, setVisible] = useState(false);
  if (!list?.length) {
    return (
      <MobileFormItem name={name} theme={theme} />
    )
  }

  const menuClick = (m) => {
    form?.setFieldsValue({
      mobile: m.mobile,
      country: {
        id: m.country,
        telephoneCode: m.countryNum
      }
    })
  }

  return (
    <Dropdown
      trigger={['click']}
      placement="bottom"
      overlay={MenuList({
        list,
        renderText: (m) => `+${m.countryNum}${m.mobile}`,
        onClick: menuClick,
        theme
      })}
      visible={visible}
    >
      {MobileFormItem({ name, setVisible, theme })}
    </Dropdown>
  )
}
const MobileInput = ({ form, initialValue, ...rest }) => {
  const onCountryChange = (country) => {
    form?.setFieldsValue({ country })
  }
  return (
    <div className={loginStyles.flexRow}>
      <span className={loginStyles.countryCode}>
        <FormItem
          className={loginStyles.country}
          name='country'
          initialValue={initialValue}
        >
          <CountrySelect
            showCountryCode={true}
            onChange={onCountryChange}
            theme={rest.theme}
          />
        </FormItem>
      </span>
      <WrapperItem {...rest} form={form} name="mobile" className={cn(loginStyles.mobile)}>
        {RenderMobile}
      </WrapperItem>
    </div>
  )
}

export const MobileLogin = ({ clickLogin, bottomClass, btnText, btnClass, theme }) => {
  const loginDisabledFn = (form) => {
    const { mobile, password, isRadioChecked } = form.getFieldsValue()
    const errors = form.getFieldsError(['password', 'mobile'])?.reduce((prev, curr) => {
      prev.push(...curr.errors)
      return prev;
    }, []);
    return !(mobile && password && isRadioChecked && !errors.length)
  }
  return (
    <BaseLogin
      account={(form) => <MobileInput form={form} theme={theme} initialValue={{ id: 'HK', telephoneCode: '852' }} />}
      clickLogin={clickLogin}
      loginDisabledFn={loginDisabledFn}
      loginType="mobile"
      bottomClass={bottomClass}
      btnText={btnText}
      btnClass={btnClass}
      theme={theme}
    />
  )
}
