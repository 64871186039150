import React from 'react';

import { Form } from 'antd';
import cn from 'classnames';

import { Button, Input } from '@element/index';
import I18n from '@utils/i18n';

import { InputField } from './InputField'

import safetyStyles from '@styleModules/safety.module.css';

export const VerifyMain = (props) => {
  const {
    isBindEmail, businessType = '-1', type = '-1', isBindPhone, className, email, okText = I18n.t('next'),
    theme, setCountDownemail, isCountDownemail, onResend, setCountDown, isCountDown, isBindGoogle
  } = props;
  return (
    <div className={cn(safetyStyles.Input, className)}>
      {isBindEmail && <InputField
        name="emailVerifyCode"
        placeholder={I18n.t('forgetPass_step_input_pls_email')}
        setCountDown={setCountDownemail}
        countdown={isCountDownemail}
        theme={theme}
        onSend={() => onResend('email', {
          businessType,
          type,
          email
        })}
      />}
      {isBindPhone && <InputField
        name="smsVerifyCode"
        placeholder={I18n.t('forgetPass_step_input_pls_mobile')}
        setCountDown={setCountDown}
        countdown={isCountDown}
        theme={theme}
        onSend={() => onResend('phone', {
          businessType,
          type,
          email: '',
          mobile: ''
        })}
      />}
      {isBindGoogle && (<Form.Item name="googleVerifyCode">
        <Input placeholder={I18n.t('user_login_relate_widget_googleVerifycode')} theme={theme} />
      </Form.Item>)}
      <Form.Item
        noStyle
        shouldUpdate={(pre, cur) => (
          (pre.smsVerifyCode !== cur.smsVerifyCode) ||
          (pre.emailVerifyCode !== cur.emailVerifyCode) ||
          (pre.googleVerifyCode !== cur.googleVerifyCode)
        )}
        data-buried="secondary-verification-next"
      >
        {({ getFieldsValue }) => {
          const formValues = Object.values(getFieldsValue())
          const isEmpy = formValues.length !== 0 && formValues.every(item => item)
          return (<Button disabled={!isEmpy} type="primary" htmlType="submit">{okText}</Button>)
        }}
      </Form.Item>
    </div>
  )
}
