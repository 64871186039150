import React, { useEffect, useState } from 'react';

import { Form, Menu } from 'antd';
import cn from 'classnames';

import { THEME, USER_AGREEMENT } from '@constants/index'
import i18n from '@utils/i18n';

import { Button } from '../../../components/Element/Button';
import { InputPassword } from '../../../components/Element/Input';
import RadioButton from '../../../components/RadioButton/RadioButton';
import { getMobileOrEmailList } from './utils';

import loginStyles from '../../../styles/modules/login.module.css';
import styles from './index.module.css';

const FormItem = Form.Item;

export const BaseLogin = ({ clickLogin, account, loginDisabledFn, loginType, bottomClass, btnText, btnClass, theme }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm()
  const handleSubmit = async (values) => {
    setLoading(true)
    try {
      await clickLogin(values);
    } catch (error) {
      form.setFieldsValue({
        password: ''
      })
    }
    setLoading(false)
  }
  return (
    <div className={loginStyles.container}>
    <Form
        name='basic'
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true, password: '', isRadioChecked: false }}
        onFinish={handleSubmit}
        autoComplete='off'
        layout='vertical'
        form={form}
        // validateTrigger={['onChange', 'onBlur']}
    >
      {account?.(form)}
      <input type="text" style={{ position: 'absolute', top: -3100 }} />
      <input name='password' type="password" style={{ position: 'absolute', top: -3000 }} />
      <FormItem
        name='password'
        validateTrigger={['onChange']}
        rules={[{
          required: true,
          message: i18n.t('can.not.be.empty', { key: i18n.t('password') }),
          validateTrigger: 'onChange'
        }]}
      >
        <InputPassword
          name='password'
          type='password'
          tabIndex="2"
          placeholder={i18n.t('Enter.Password')}
          forgetPwd={true}
          maxLength={20}
          loginType={loginType}
          className={cn({
            [styles.password]: theme !== THEME.DARK
          })}
          theme={theme}
        />
      </FormItem>
      <div className={`${cn(bottomClass, styles.bottomClass)}  bottom-class`} >
      <FormItem shouldUpdate>
        {(form) => {
          const isRadioChecked = form.getFieldValue('isRadioChecked');
          return (
            <FormItem name="isRadioChecked" initialValue={false}>
              <RadioButton
                theme={theme}
                isRadioChecked={isRadioChecked}
                onChange={() => {
                  form.setFieldsValue({ isRadioChecked: !isRadioChecked })
                }}
                radioText={i18n.t('login.agree')}
                linkUrl={USER_AGREEMENT[i18n.locale]}
                linkContent={<span className='DINPro'>{i18n.t('user.agreement')}</span>}
                linkAfterContent={i18n.t('Kikitrade.policy')}
              />
            </FormItem>
          )
        }}
      </FormItem>
      <FormItem shouldUpdate>
        {(form) => {
          return (
            <Button
              type='primary'
              htmlType='submit'
              disabled={loginDisabledFn(form)}
              btnClass={btnClass}
              loading={loading}
              content={<span className={cn('Medium')}>{btnText || i18n.t('login')}</span>}
              data-buried="login"
              data-buried-type={`login-${loginType}`}
            />)
        }}
      </FormItem>

      </div>
    </Form>
  </div>
  )
}

export const WrapperItem = ({ children, form, name, className, ...rest }) => {
  const [list, setList] = useState([]);
  useEffect(() => {
    const list = getMobileOrEmailList(name)
    setList(list)
  // eslint-disable-next-line
  }, [])

  return (
    <div className={cn(styles.email, className)}>
      {children?.({ name, list, form, ...rest })}
    </div>
  )
}

export const MenuList = ({ list, renderText, onClick, theme }) => {
  const MenuListRender = () => (
    <Menu className={cn(styles.menu, {
      [styles.dark_menu]: theme === THEME.DARK
    })} tabIndex="-1">
      {
        list.map((m, index) => (
          <Menu.Item className={cn(styles.menuItem, {
            [styles.dark_menuitem]: theme === THEME.DARK
          })} key={index} onClick={() => onClick(m)}>
            {renderText(m)}
          </Menu.Item>
        ))
      }
    </Menu>
  )
  return MenuListRender;
}
