import React, { useState } from 'react';

import { Dropdown, Form } from 'antd';
import cn from 'classnames'

import { THEME } from '@constants/index'
import { Input } from '@element/index';
import i18n from '@utils/i18n';

import { BaseLogin, MenuList, WrapperItem } from './BaseLogin';

import styles from './index.module.css';
import loginStyles from '@styles/modules/login.module.css';

const FormItem = Form.Item;

const EmailFormItem = ({ name, setVisible, theme }) => {
  console.log('line 12', theme)
  return (
    <FormItem
      className={loginStyles.email}
      name={name}
      validateTrigger={['onChange', 'onBlur']}
      rules={[
        {
          type: 'email',
          message: i18n.t('wrong.format', { key: i18n.t('email') }),
          validateTrigger: 'onBlur'
        },
        {
          required: true,
          message: i18n.t('can.not.be.empty', { key: i18n.t('email') }),
          validateTrigger: 'onChange'
        }
      ]}
    >
      <Input
        name='email'
        placeholder={i18n.t('register_input_email')}
        className={cn({ [styles.emailAccount]: theme !== THEME.DARK })}
        onBlur={() => {
          setTimeout(() => {
            setVisible?.(false)
          }, 200)
        }}
        theme={theme}
        tabIndex='1'
        onFocus={() => setVisible?.(true)}
        onInput={e => {
          const value = e.target.value;
          if (value) {
            setVisible?.(false)
          } else {
            setVisible?.(true)
          }
        }}
      />
    </FormItem>
  )
}

const RenderEmail = ({ name, list, form, theme }) => {
  const [visible, setVisible] = useState(false);
  if (!list?.length) {
    return (
      <EmailFormItem name={name} theme={theme} />
    )
  }
  return (
    <Dropdown
      trigger={['click']}
      overlay={MenuList({
        list,
        renderText: (m) => m.email,
        onClick: (m) => {
          form?.setFieldsValue({ [name]: m.email })
        },
        theme
      })}
      placement="bottom"
      visible={visible}
    >
      {EmailFormItem({ name, setVisible, theme })}
    </Dropdown>
  )
}

const EmailInput = ({ form, name, ...rest }) => {
  return (
    <>
      <input type="text" name="email" style={{ position: 'absolute', top: -3100 }} />
      <WrapperItem {...rest} form={form} name={name}>
        {RenderEmail}
      </WrapperItem>
    </>
  )
}

export const EmailLogin = ({ clickLogin, bottomClass, btnText, btnClass, theme }) => {
  const loginDisabledFn = (form) => {
    const { email, password, isRadioChecked } = form.getFieldsValue()
    const errors = form.getFieldsError(['email', 'password'])?.reduce((prev, curr) => {
      prev.push(...curr.errors)
      return prev;
    }, []);
    return !(email && password && isRadioChecked && !errors.length)
  }
  return (
    <BaseLogin
      account={(form) => <EmailInput theme={theme} form={form} name="email" initialValue="" />}
      clickLogin={clickLogin}
      loginDisabledFn={loginDisabledFn}
      loginType="email"
      bottomClass={bottomClass}
      btnText={btnText}
      btnClass={btnClass}
      theme={theme}
    />
  )
}
