import React from 'react'
import { Modal } from 'antd';
import LayoutStyle from './index.module.css';

export default function Layouts (props) {
  // const { activekey: defaultactivekey = '1', imgShow = false } = props
  return (
    <Modal
      {...props}
      centered
      footer={[]}
      wrapClassName={`${LayoutStyle.modal} ${props.wrapClassName}`}
    >
      {props.children}
   </Modal>
  )
}
