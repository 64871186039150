import React from 'react';
import I18n from '@utils/i18n';
import WapperModal from '@components/WapperModal';
import safetyStyles from '@styleModules/safety.module.css';

export const ModalWrapper = (props) => {
  const { children, closeModal, visible, destroyOnClose } = props;
  return (
    <WapperModal
      visible={visible}
      maskClosable={false}
      destroyOnClose={destroyOnClose}
      title={I18n.t('login_twice_verification')}
      wrapClassName={safetyStyles.modal}
      onCancel={closeModal}
    >
     <div className={safetyStyles.main}>
        <p className={safetyStyles.title}>{I18n.t('login_twice_verification')}</p>
        {children}
      </div>
    </WapperModal>
  )
}
